<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('deviceGateway.editDevice','编辑网关设备')"
    width="1500px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('elevatorNetApply.labelWidth','120px')"
      :model="model">
      <div class="vm-separate">
        <el-form-item :label="$l('elevator.joinStatus','入网状态')">
          <el-switch
            v-model="model.inNetState"
            :active-text="$l('elevator.yes','是')"
            :inactive-text="$l('elevator.No','否')"></el-switch>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.createCreditCode','设备型号')" prop="deviceModel">
          <el-input v-model.trim="model.deviceModel" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.createCreditCode','信号类型')" prop="signalType">
          <el-input v-model.trim="model.signalType" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.createCreditCode','终端编号')" prop="dtuCode">
          <el-input v-model.trim="model.dtuCode" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.createCreditCode','绑定状态')" prop="bindState">
          <el-input v-model.trim="model.bindState" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','软件版本')" prop="softVer">
          <el-input v-model.trim="model.softVer" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','硬件版本')" prop="硬件版本">
          <el-input v-model.trim="model.hardVer" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','SIM卡号')" prop="simCode">
          <el-input v-model.trim="model.simCode" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','SIM卡ICCID')" prop="simICCID">
          <el-input v-model.trim="model.simICCID" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','信号')" prop="signalStrength">
          <el-input v-model.trim="model.signalStrength" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','设备温度')" prop="temperature">
          <el-input v-model.trim="model.temperature" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','ip')" prop="signalStrength">
          <el-input v-model.trim="model.ip" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','端口')" prop="temperature">
          <el-input v-model.trim="model.port" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','APN名称')" prop="signalStrength">
          <el-input v-model.trim="model.apnName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','主板状态')" prop="temperature">
          <el-input v-model.trim="model.connectionStatus" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('elevatorNetApply.maintEmpName','用户名')" prop="signalStrength">
          <el-input v-model.trim="model.appUserName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('elevatorNetApply.maintEmpPhone','密码')" prop="temperature">
          <el-input v-model.trim="model.apnPassword" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          deviceModel: "",
          signalType: "",
          dtuCode: "",
          bindState: "",
          softVer: "",
          hardVer: "",
          simCode: "",
          simICCID: "",
          signalStrength: "",
          temperature: "",
          electricityQuantity: "",
          ip: "",
          port: "",
          apnName: "",
          appUserName: "",
          apnPassword: "",
          connectionStatus: "",
          inNetState: false,
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("deviceGateway/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("deviceGateway", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
