<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('deviceGateway.setPhone','设置对讲号码')"
    width="500px"
    top="3vh">
    <div>
      <el-form
        ref="formValidate"
        class="form"
        label-width="70px">
        <el-form-item :label="$l('deviceGateway.phone','对讲号码')">
          <el-input
            v-model="phone1"
            type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
          <el-input
            v-model="phone2"
            type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
          <el-input
            v-model="phone3"
            type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
          <el-input
            v-model="phone4"
            type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </el-form>

    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        isBlack: 0,
        dialogVisible: false,
        phone1: "",
        phone2: "",
        phone3: "",
        phone4: "",
        dtuCode: "",
      };
    },
    methods: {
      open(dtuCode) {
        this.dtuCode = dtuCode;
        this.getData(dtuCode);
        this.dialogVisible = true;
      },
      getData(dtuCode) {
        this.$http.get("deviceGateway/getCallPhone/" + dtuCode).then(res => {
          if (res.data) {
            this.phone1 = res.data.callPhone1;
            this.phone2 = res.data.callPhone2;
            this.phone3 = res.data.callPhone3;
            this.phone4 = res.data.callPhone4;
          }
        }).catch((error) => {
        });
      },
      handleSubmit() {
        if (!this.phone1 || !this.phone2 || !this.phone3 || !this.phone4) {
          this.$message.error("请输入三个号码");
        }
        let str = this.phone1 + "," + this.phone2 + "," + this.phone3 + "," + this.phone4;
        this.$http.get("deviceGateway/setPhone/" + this.dtuCode + "/" + str).then(res => {
          this.$message.success("操作成功");
          this.dialogVisible = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
