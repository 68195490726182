<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="deviceGateway"
      @selection-change="handleSelectionChange"
      @clear="clearDate">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('deviceGateway.bindState', '绑定状态')">
          <el-select
            v-model.trim="search.bindState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in bindStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('deviceGateway.linkStatus', '主板状态')">
          <el-select
            v-model="search.connectionStatus"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('150px', '100px') }"
            clearable>
            <el-option
              v-for="item in connectionStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('deviceGateway.onlineState', '在线状态')">
          <el-select
            v-model.trim="search.onlineState"
            :placeholder="$t('common.pleaseSelect')"
            :style="{ width: $l('100px', '100px') }"
            clearable>
            <el-option
              v-for="item in onlineStateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('deviceGateway.onlineUpdateTime', '接入时间')">
          <el-date-picker
            v-model="accessTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            clearable
            @change="handleDate">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column type="selection" width="50" align="center">
      </el-table-column>
      <el-table-column
        fixed
        prop="dtuCode"
        :label="$l('deviceGateway.dtuCode', '终端编号')"
        width="120"
        align="center">
        <template slot-scope="scope">
          <a v-if="scope.row.bindState" @click="$refs.deviceNode.open(scope.row.dtuCode)">
            {{scope.row.dtuCode}}
          </a>
          <div v-else>{{scope.row.dtuCode}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="onlineState" :label="$l('deviceGateway.dtuStatus', '在线状态')" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.onlineState === 1" type="success">
            {{$l("deviceGateway.dtuOnline", "在线")}}
          </el-tag>
          <el-tag v-if="scope.row.onlineState === 0" type="info">
            {{$l("deviceGateway.dtuOffline", "离线")}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceModel"
        :label="$l('deviceGateway.deviceModel', '设备型号')"
        width="80"
        align="center"></el-table-column>
      <el-table-column
        prop="signalType"
        :label="$l('deviceGateway.signalType', '信号类型')"
        width="80"
        align="center"></el-table-column>
      <el-table-column prop="simCode" :label="$l('deviceGateway.simCode', 'SIM卡号')" width="120" align="center"></el-table-column>
      <el-table-column
        width="200"
        prop="simICCID"
        :label="$l('deviceGateway.simICCID', 'SIM卡ICCID')"
        show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column
        prop="softVer"
        :label="$l('deviceGateway.softVer', '软件版本')"
        width="100"
        align="center"></el-table-column>
      <el-table-column
        prop="hardVer"
        :label="$l('deviceGateway.hardVer', '硬件版本')"
        width="100"
        align="center"></el-table-column>
      <el-table-column
        prop="signalStrength"
        :label="$l('deviceGateway.signalStrength', '信号')"
        width="70"
        align="center"></el-table-column>
      <el-table-column
        prop="connectionStatus"
        :label="$l('deviceGateway.linkStatus', '主板状态')"
        width="110"
        align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.connectionStatus === 1" type="success">
            {{$l("deviceGateway.communicationNormal", "正常")}}
          </el-tag>
          <el-tag v-if="scope.row.connectionStatus === 0" type="warning">
            {{$l("deviceGateway.communicationFailed", "断开")}}
          </el-tag>
          <el-tag v-if="scope.row.connectionStatus === -1" type="danger">
            {{$l("deviceGateway.invalid", "无效")}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="hasIntercom"
        :label="$l('deviceGateway.linkStatus', '对讲')"
        width="70"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.hasIntercom === true">
            有
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="hasVideo"
        :label="$l('deviceGateway.linkStatus', '音视频')"
        width="70"
        align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.hasVideo === true">
            有
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="onlineUpdateTime"
        :label="$l('deviceGateway.updateTime', '更新时间')"
        width="200"
        align="center"></el-table-column>
      <el-table-column
        prop="accessTime"
        :label="$l('deviceGateway.accessTime', '接入时间')"
        width="200"
        align="center"></el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" width="250" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="$refs.logList.open(scope.row.dtuCode)">
            {{$t("device.record")}}
          </el-button>
          <el-button v-if="scope.row.hasVideo" type="text" @click="subVideo0(scope.row.dtuCode)">{{$l("", "音频")}}</el-button>
          <el-button v-if="scope.row.hasVideo" type="text" @click="subVideo1(scope.row.dtuCode)">{{$l("", "视频")}}</el-button>
          <el-button v-if="scope.row.hasVideo" type="text" @click="subVideo2(scope.row.dtuCode)">{{$l("", "FLV")}}</el-button>
          <el-button v-if="scope.row.hasIntercom" type="text" @click="setPhone(scope.row.dtuCode)">{{$l("", "对讲设置")}}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <device-node ref="deviceNode"></device-node>
    <device-log-list ref="logList"></device-log-list>
    <set-phone ref="setPhone"></set-phone>
  </div>
</template>
<script>
  import auth from "@/util/auth";
  import SetPhone from "@/views/deviceGateway/SetPhone";
  import EditPage from "./DeviceGatewayEdit";
  import DeviceNode from "@/views/deviceGateway/DeviceNode";
  import DeviceLogList from "@/views/deviceGateway/DeviceLogList";

  const moduleName = "deviceGateway";
  export default {
    components: {EditPage, DeviceNode, DeviceLogList, SetPhone},
    data() {
      return {
        simCardAuth: this.$auth(803),
        upgradeAuth: this.$auth(802),
        tableData: [],
        multiSelect: [],
        total: 0,
        currentPage: 1,
        adSearch: false,
        importLoading: false,
        loading: true,
        uploadUrl: window.config.SERVER_URL + "deviceGateway/import",
        uploadHeaders: {Authorization: auth.getToken()},
        accessTime: [],
        search: {
          filter: "",
          connectionStatus: "",
          inNetState: "",
          onlineState: "",
          access_beginTime: "",
          access_endTime: "",
        },
        bindStateOptions: [
          {value: 0, label: this.$l("deviceGateway.bound", "未绑定")},
          {value: 1, label: this.$l("deviceGateway.unbound", "已绑定")},
        ],
        connectionStatusOptions: [
          {value: 0, label: this.$l("deviceGateway.communicationFailed", "断开")},
          {value: 1, label: this.$l("deviceGateway.communicationNormal", "正常")},
          {value: -1, label: this.$l("deviceGateway.invalid", "无效")},
        ],
        inNetStateOptions: [
          {value: true, label: this.$l("elevator.joined", "已入网")},
          {value: false, label: this.$l("elevator.notJoined", "未入网")},
        ],
        onlineStateOptions: [
          {value: 0, label: this.$l("deviceGateway.dtuOffline", "离线")},
          {value: 1, label: this.$l("deviceGateway.dtuOnline", "在线")},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      clearDate() {
        this.accessTime = [];
      },
      subVideo0(dtuCode) {
        this.loading = true;
        this.$http.get("deviceGateway/subVideo/" + dtuCode + "/" + 0).then(res => {
          if (res.data) {
            window.open(res.data);
          } else {
            this.$message.error("音频链接为空");
          }
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      subVideo1(dtuCode) {
        this.loading = true;
        this.$http.get("deviceGateway/subVideo/" + dtuCode + "/" + 1).then(res => {
          if (res.data) {
            window.open(res.data);
          } else {
            this.$message.error("视频链接为空");
          }
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      subVideo2(dtuCode) {
        this.loading = true;
        this.$http.get("deviceGateway/subVideo/" + dtuCode + "/" + 2).then(res => {
          if (res.data) {
            alert(res.data);
          } else {
            this.$message.error("flv链接为空");
          }
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      subStatus(dtuCode) {
        this.$http.get("deviceGateway/subStatus/" + dtuCode).then(res => {
          this.$message.success("操作成功");
        });
      },
      setPhone(dtuCode) {
        this.$refs.setPhone.open(dtuCode);
      },
      handleDate() {
        if (this.accessTime != null) {
          this.search.access_beginTime = this.accessTime[0];
          this.search.access_endTime = this.accessTime[1];
        } else {
          this.search.access_beginTime = "";
          this.search.access_endTime = "";
        }
      },
      getList(pageNo) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNo);
      },
      handleSelectionChange(val) {
        this.multiSelect = val;
      },
      handleVideo(row) {
        window.open(row.videoUrl);
      },
      multiSelectSure() {
        this.$emit("multi-select", this.multiSelect);
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.dtuCode") + " " + row.dtuCode + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {type: "warning"}).then(() => {
          this.$http.delete("deviceGateway", row.id).then(() => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
